import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { del, get, post, put, putPresigned } from "../../services/API/ApiClient";
import { defaultRequestBodyType, DocumentUploadArgs } from "../../constant/document";
import { CancelTokenSource } from "axios";
import { RcFile } from "antd/es/upload";
import { openNotification, openNotificationWithIcon } from "../../components/Template/NotificationComponent";

export const quickTextTranslation = createAsyncThunk<any, any, { state: RootState }>("engageTranslation/quickTextTranslation", async (args, { getState, dispatch }) => {
  try {
    const response = await post(`/common/translate/text`, "engage-translate", args);
    return response;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? "Failed to translate");
  }
});

export const fetchQuickTranslateLanguageList = createAsyncThunk<any, void, { state: RootState }>("engageTranslation/fetchQuickTranslateLanguageList", async (args, { getState, dispatch }) => {
  try {
    const response_source_language_list = await get("/common/languages/pick-list", "engage-translate");
    const sourceLanguageList = response_source_language_list.data.map((language: any) => ({
      label: language.name,
      value: language.id,
      id: language.id,
    }));

    return { sourceLanguageList: sourceLanguageList };
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to fetch source languages");
  }
});

export const fetchTargetLanguageList = createAsyncThunk<any, number, { state: RootState }>("engageTranslation/fetchTargetLanguageList", async (sourceLanguage, { getState, dispatch }) => {
  try {
    const response_target_language_list = await get(`/common/languages/${sourceLanguage}/targets`, "engage-translate");
    const targetLanguageList = response_target_language_list.data.map((language: any) => ({
      label: language.name,
      value: language.id,
      id: language.id,
    }));
    return { targetLanguageList: targetLanguageList };
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to fetch target languages");
  }
});

export const updateUploadProgress = createAction<{
  progress: number;
  fileName: string;
}>("engageTranslation/updateUploadProgress");
export const updateUploadCancelToken = createAction<{
  cancelToken: CancelTokenSource;
  fileName: string;
}>("engageTranslation/updateUploadCancelToken");

export const documentUpload = createAsyncThunk<any, DocumentUploadArgs, { state: RootState }>("engageTranslation/documentUpload", async (documentUploadData, { dispatch, getState }) => {
  const file = documentUploadData.file as RcFile;
  const documentReqBody = {
    fileName: documentUploadData.file.name,
    sourceLanguageId: documentUploadData.sourceLanguageId,
    targetLanguageIds: documentUploadData.targetLanguageIds,
    uploadedBy: documentUploadData.uploadedBy,
    tags: documentUploadData.tags,
    additionalNotes: documentUploadData.additionalNotes,
    isQcRequired: documentUploadData.isQcRequired,
  };

  //calling dispatch to update documentUpload state
  const setCancelToken = (cancelToken: CancelTokenSource) => {
    dispatch(
      updateUploadCancelToken({
        cancelToken: cancelToken,
        fileName: documentUploadData.file.name,
      })
    );
  };
  const onUploadProgress = (progress: number) => {
    dispatch(updateUploadProgress({ progress: progress, fileName: documentUploadData.file.name }));
  };

  try {
    const response = await putPresigned("/client/documents/upload", "engage-translate", { file: file, ...documentReqBody });
    await put(response?.data?.presignedUrl, file, onUploadProgress, setCancelToken);
    // openNotificationWithIcon(response.success, response.data, documentReqBody.fileName);
    return response.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Failed to upload file");
  }
});

export const fetchDocuments = createAsyncThunk<any, { view: string | null; documentId?: number }, { state: RootState }>("engageTranslation/fetchDocuments", async (args, { getState, dispatch, rejectWithValue }) => {
  let reqBody;
  let reqUrl;

  switch (args.view) {
    case "document":
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
        viewName: "document",
      };
      reqUrl = "/client/documents/search";
      break;
    case "audit-log":
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.auditLogPageState.currentRequestBody,
        DocumentId: args.documentId,
      };
      reqUrl = "/common/documents/audit-entries";
      break;
    default:
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
        viewName: "document",
      };
      reqUrl = "/client/documents/search";
      break;
  }
  try {
    const response = await post(reqUrl, "engage-translate", reqBody);

    return {
      data: response.data.results,
      searchHeading: response.data.searchResultHeading,
    };
  } catch (err: any) {
    console.log("fetching documents error: ", err);
    throw new Error(err.response?.data ?? "Failed to fetch file");
  }
});

export const fetchPaginateDocuments = createAsyncThunk<any, { view: string; documentId?: number }, { state: RootState }>("engageTranslation/fetchPaginateDocuments", async (args, { getState, dispatch }) => {
  let reqBody;
  let reqUrl;
  switch (args.view) {
    case "document":
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
        viewName: "document",
        pageNum: getState().engageTranslationSlice.engageTranslateMainState.documentTabState.pagination.page,
      };
      reqUrl = "/client/documents/search";
      break;
    case "audit-log":
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.auditLogPageState.currentRequestBody,
        viewName: "audit-log",
        pageNum: getState().engageTranslationSlice.engageTranslateMainState.auditLogPageState.pagination.page,
      };
      reqUrl = "/common/documents/audit-entries";
      break;
    default:
      reqBody = {
        ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
        viewName: "document",
        pageNum: getState().engageTranslationSlice.engageTranslateMainState.documentTabState.pagination.page,
      };
      reqUrl = "/client/documents/search";
      break;
  }
  try {
    const response = await post(reqUrl, "engage-translate", reqBody);

    return {
      data: response.data.results,
      hasMore: response?.data?.results?.length > 0,
      view: args.view,
    };
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to fetch file");
  }
});

export const fetchRefreshDocuments = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchRefreshLogos", async (args, { getState, dispatch, rejectWithValue }) => {
  // const currentDataLength = getState().livePlatformState.data.length === 0 ? 30 : getState().livePlatformState.data.length;
  const currentDataLength = getState().engageTranslationSlice.engageTranslateMainState.documentTabState.data.length === 0 ? 30 : getState().engageTranslationSlice.engageTranslateMainState.documentTabState.data.length;
  try {
    const reqBody = {
      ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
      pageSize: Math.ceil(currentDataLength / 30) * 30,
    };

    const response = await post("/client/documents/search", "engage-translate", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const autoRefresh = createAsyncThunk<any, any, { state: RootState }>("logoProduction/autoRefresh", async (args, { getState, dispatch, rejectWithValue }) => {
  if (getState().engageTranslationSlice.engageTranslateMainState.documentTabState.autoRefreshState.currentIntervalId) {
    clearInterval(getState().engageTranslationSlice.engageTranslateMainState.documentTabState.autoRefreshState.currentIntervalId);
  }
  if (getState().engageTranslationSlice.engageTranslateMainState.documentTabState.autoRefreshState.autoRefresh) {
    const intervalId = setInterval(() => {
      dispatch(fetchRefreshDocuments({}));
    }, 5000);
    dispatch(setAutoRefresh({ intervalId }));
  }
});

export const setAutoRefresh = createAction<{ intervalId: NodeJS.Timer }>("logoProduction/setAutoRefresh");
export const stopAutoRefresh = createAction("logoProduction/stopAutoRefresh");
export const switchAutoFresh = createAction<{ autoRefresh: boolean }>("logoProduction/switchAutoFresh");

export const searchDocuments = createAsyncThunk<any, { view: string; searchKeyword: string }, { state: RootState }>("engageTranslation/searchDocuments", async (args, { getState, dispatch }) => {
  dispatch(resetTabState(args.view));
  dispatch(
    updateRequestBody({
      requestBody: {
        ...getState().engageTranslationSlice.engageTranslateMainState.documentTabState.currentRequestBody,
        keyword: args.searchKeyword,
      },
      view: args.view,
    })
  );
  dispatch(fetchDocuments({ view: args.view }));
});

export const getDocumentInfo = createAsyncThunk<any, { documentID: number; ID: number }, { state: RootState }>("engageTranslation/getDocumentInfo", async (args) => {
  try {
    const response = await get(`/common/documents/${args.documentID}/translated-docs/${args.ID}`, "engage-translate");

    if (response.data.fileFormat === "docx") {
      const fileResponseInput = await fetch(response.data.inputPathPresignedUrl);
      const sfdtInput = await fileResponseInput.json();
      const fileResponseOutput = await fetch(response.data.outputPathPresignedUrl);
      const sfdtOutput = await fileResponseOutput.json();
      return { ...response.data, sfdtInput: sfdtInput, sfdtOutput: sfdtOutput };
    } else {
      return { ...response.data };
    }
  } catch (err: any) {
    throw new Error("Failed to get document information");
  }
});

export const transferToQC = createAsyncThunk<any, any, { state: RootState }>("engageTranslation/transferToQC", async (args, { getState, dispatch }) => {
  try {
    const response = await post(`/client/documents/qctransfer`, "engage-translate", { translatedDocId: args.documentId, documentId: args.ID, comments: args.comments });
    return response.data;
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to transfer to QC");
  }
});

export const stopTranslation = createAsyncThunk<any, { documentID: number; ID: number }, { state: RootState }>("engageTranslation/stopTranslation", async ({ documentID, ID }, { getState, dispatch }) => {
  try {
    const response = await post(`/common/documents/${documentID}/translated-docs/${ID}/stop-translate`, "engage-translate", {});
    // const metadata = await get(`/production/documents/${selectedRecordID}/metadata`, "engage-translate");
    openNotification("Stop Translation", "Translation of the document is stopping", response.data);
    dispatch(fetchDocuments({ view: "document" }));
    return response.data;
  } catch (err: any) {
    openNotification("Stop Translation", `Failed to stop translation. ${err.response?.message}`);
    throw new Error(err.response?.data ?? "Failed to stop translation");
  }
});

// export const fetchViewDocumentDetailsPresigned = createAsyncThunk<any, any, { state: RootState }>("engageTranslation/fetchViewDocumentDetailsPresigned", async (args, { getState, dispatch }) => {
//   try {
//     const response = await get(`/common/documents/${args.selectedRecordID}`, "engage-translate");

//     if (response.data.fileFormat === "docx") {
//       const fileResponseInput = await fetch(response.data.inputPathPresignedUrl);
//       const sfdtInput = await fileResponseInput.json();
//       const fileResponseOutput = await fetch(response.data.outputPathPresignedUrl);
//       const sfdtOutput = await fileResponseOutput.json();
//       return { ...response.data, sfdtInput: sfdtInput, sfdtOutput: sfdtOutput };
//     } else {
//       return { ...response.data };
//     }
//   } catch (err: any) {
//     throw new Error(err.response?.data ?? "Failed to fetch file");
//   }
// });

export const getDocumentPresigned = createAsyncThunk<any, { documentID: number; ID: number }, { state: RootState }>("engageTranslation/getDocumentPresigned", async ({ ID, documentID }, { rejectWithValue }) => {
  try {
    const response = await get(`/common/documents/${ID}/translated-docs/${documentID}/download-urls`, "engage-translate");

    //create a link and download click without getting the user to other page
    const link = document.createElement("a");
    link.href = response.data.targetDocPresignedUrl;
    link.download = "download.pdf";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteDocument = createAsyncThunk<any, { documentID: number; ID: number }, { state: RootState }>("engageTranslation/deleteDocument", async ({ documentID, ID }, { getState, dispatch }) => {
  try {
    const metadata = await get(`/common/documents/${documentID}/translated-docs/${ID}`, "engage-translate");
    const response = await del(`/common/documents/${documentID}/translated-docs/${ID}`, "engage-translate");
    dispatch(fetchDocuments({ view: "document" }));
    openNotification("Deleted Document", "Document deleted successfully", { data: metadata.data, ID: ID });
    return response.data;
  } catch (err: any) {
    openNotification("Deleted Document", "Failed to delete document");
    throw new Error(err.response?.data ?? "Failed to delete document");
  }
});

export const uploadedStatus = createAsyncThunk<any, number, { state: RootState }>("engageTranslation/uploadedStatus", async (selectedRecordID, { getState, dispatch }) => {
  try {
    const response = await get(`/client/documents/${selectedRecordID}/statuspoll`, "engage-translate");
    return response.data;
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to delete document");
  }
});

export const dropDownLanguages = createAsyncThunk<any, number, { state: RootState }>("engageTranslation/dropDownLanguages", async (selectedDocumentId, { getState, dispatch }) => {
  try {
    const response = await get(`/client/documents/${selectedDocumentId}/targetlanguage`, "engage-translate");
    return response.data;
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to delete document");
  }
});

export const openDeleteModal = createAction("engageTranslation/openDeleteModal");
export const closeDeleteModal = createAction("engageTranslation/closeDeleteModal");
export const updateRequestBody = createAction<{
  requestBody: defaultRequestBodyType;
  view: string;
}>("engageTranslation/updateRequestBody");
export const setSelectedRecord = createAction<{
  selectedID: number;
  selectedDocumentID: number;
}>("engageTranslation/setSelectedRecord");
export const setSelectedName = createAction<{
  selectedRecordName: string;
}>("engageTranslation/setSelectedName");
export const clearUploadStatusData = createAction("engageTranslation/clearUploadStatusData");
export const auditLogModalOpen = createAction("engageTranslation/auditLogModalOpen");
export const auditLogModalClose = createAction("engageTranslation/auditLogModalClose");
export const auditLogModalData = createAction<{ data: any }>("engageTranslation/auditLogModalData");
export const resetTabState = createAction<string>("engageTranslation/resetTabState");
export const clearDocData = createAction("engageTranslation/clearDocData");
