import { API } from "aws-amplify";
import axios, { CancelTokenSource } from "axios";
import { getSessionData } from "../auth/AuthAPI";
import { useNavigate } from "react-router";
import { RcFile } from "antd/es/upload";
import { error } from "console";

export const myInitHeader = (currentSession: any, contentType?: any) => ({
  headers: {
    Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
    contentType: contentType || null,
  },
});

export async function get(path: string, name: string) {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());

  return API.get(apiName, path, myNewInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export async function getReqBody(path: string, name: string, data: any) {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  let myInit = {
    body: data,
    headers: { ...myNewInit.headers },
  };

  return API.get(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export async function post(path: string, name: string, data: any, customresHeader = {}, customreqHeader = {}) {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  let myInit = {
    body: data,
    headers: { ...myNewInit.headers, ...customreqHeader },
    ...customresHeader,
  };
  return API.post(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
      // throw getValidErrors(e, path);
    });
}

export async function putPresigned(path: string, name: string, data: any, customresHeader = {}, customreqHeader = {}) {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  let myInit = {
    body: data,
    headers: { ...myNewInit.headers, ...customreqHeader },
    ...customresHeader,
  };
  return API.put(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log(e);
      // throw getValidErrors(e, path);
    });
}

export const put = async (path: string, data: RcFile, onUploadProgress: (progress: number) => void, setCancelToken: (cancelTokenSource: CancelTokenSource) => void) => {
  const cancelTokenSource = axios.CancelToken.source();
  setCancelToken(cancelTokenSource);
  return axios
    .put(path, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          onUploadProgress(progress);
        }
      },
      headers: { "Content-Type": data.type },
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (axios.isCancel(e)) {
        throw new Error("Upload cancelled");
      }
      console.log(e);
      throw new Error("Error during upload", e);
      // throw getValidErrors(e, path);
    });
};

export const save = async (path: string, data: Blob) => {
  return axios
    .put(path, data, {
      headers: { "Content-Type": data.type },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log(e);
      throw new Error("Error during upload", e);
      // throw getValidErrors(e, path);
    });
};

export const del = async (path: string, name: string) => {
  let apiName = name;
  const myNewInit = myInitHeader(await getSessionData());
  return API.del(apiName, path, myNewInit)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error("Error during delete", e);
      // throw getValidErrors(e, path);
    });
};

const getValidErrors = (error: any, apiUrl: string) => {
  const errorDetails = error.response;
  window.location.href = "/auth/error";
  return {
    error: true,
    success: false,
    statusText: `${error.response} ${apiUrl}`,
    status: 500,
    message: "server is temporarily down.",
  };
};
