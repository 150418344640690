import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Select, Input, Button, Dropdown, Spin, Modal, Form } from "antd";
import { FontIcon } from "@fluentui/react";
import { Checkbox } from "antd";
import BackBtn from "../../components/Template/BackBtn";
import "./TranslationDetailPage.css";
import TextArea from "antd/es/input/TextArea";
import PDFViewer from "../../components/PDFViewerComponent/PDFViewer";
import { clearDocData, clearUploadStatusData, dropDownLanguages, getDocumentInfo, getDocumentPresigned, stopAutoRefresh, transferToQC } from "../../redux/EngageTranslation/EngageTranslationAction";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import TransferToQCStatusModal from "../../components/Template/TransferToQCModal/TransferToQCStatusModal";
import DocumentEditorViewer from "../../components/DocumentEditorViewer/DocumentEditorViewer";

function TranslationDetailPage(props: any) {
  const outputWordDocumentEditorRef = useRef(null) as any;

  const inputWordDocumentEditorRef = useRef(null) as any;
  const outputDocumentEditorRef = useRef(null) as any;
  const inputDocumentEditorRef = useRef(null) as any;
  const [loadingRight, setLoadingRight] = useState(false);
  const [selectedOutput, setSelectedOutput] = useState<string>("");
  const [languageOptions, setLanguageOptions] = useState<any>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<any>();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [comments, setComments] = useState("");
  const [transferToQcConfirmationOpenState, setTransferToQcConfirmationOpenState] = useState(false);
  const viewDocumentState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.viewDocumentState);
  const { state } = useLocation();
  console.log("state", state);

  const ID = Number(location.pathname.split("/")[2]);
  const documentID = Number(location.search.split("=")[1].split("&")[0]);
  const lang = location.search.split("=")[2];

  useEffect(() => {
    dispatch(getDocumentInfo({ documentID: ID, ID: documentID }));
    dispatch(dropDownLanguages(ID));
    dispatch(clearUploadStatusData());
    dispatch(stopAutoRefresh());

    return () => {
      dispatch(clearDocData());
    };
  }, []);

  useEffect(() => {
    let languageOptions: any = [];

    if (viewDocumentState.dropDownLanguages.translatedDocs) {
      viewDocumentState.dropDownLanguages.translatedDocs.forEach((lang: any) => {
        languageOptions.push({ label: lang.targetLanguageName.replace(/\s/g, ""), value: `${Number(lang.id)},${lang.targetLanguageName.replace(/\s/g, "")}`, id: lang.id });
      });

      setLanguageOptions(languageOptions);
      setDefaultLanguage(viewDocumentState?.data?.targetLanguageName);
    }
  }, [viewDocumentState.dropDownLanguagesLoading, viewDocumentState.data.targetLanguageName]);

  const normalOptions = [
    {
      key: "download",
      label: "Download",
      icon: (
        <FontIcon
          style={{ marginTop: "1px" }}
          iconName="Download"
        />
      ),
    },
    {
      key: "transfertoqc",
      label: "Transfer to QC",
      icon: (
        <FontIcon
          style={{ marginTop: "1px" }}
          iconName="RedEye"
        />
      ),
    },
  ];

  const noQcOptions = [
    {
      key: "download",
      label: "Download",
      icon: (
        <FontIcon
          style={{ marginTop: "1px" }}
          iconName="Download"
        />
      ),
    },
  ];

  const onClick = ({ key }: any) => {
    setSelectedOutput(key);

    if (key === "download") {
      dispatch(getDocumentPresigned({ documentID: documentID, ID: ID }));
    }
  };

  function openTransferToQCConfirmation() {
    setTransferToQcConfirmationOpenState(true);
  }
  function closeTransferToQCConfirmation() {
    setTransferToQcConfirmationOpenState(false);
  }

  async function transferToQcFunction() {
    await dispatch(transferToQC({ ID: ID, documentId: documentID, comments: comments }));
    setSelectedOutput("");
    openTransferToQCConfirmation();
  }

  return (
    <div className="body-container d-flex flex-column h-100 gap-3">
      {viewDocumentState.loading ? (
        <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mt-3">
            <div className="d-flex align-items-center gap-3">
              <BackBtn from={state} />
              <h4>{viewDocumentState.data.name}</h4>
            </div>
            <div className="download-options-container">
              <Select
                placeholder="Please select"
                defaultValue={lang}
                style={{
                  width: 300,
                }}
                onChange={(e) => {
                  navigate(`/view-document/${ID}?target=${e.split(",")[0]}&language=${e.split(",")[1]}`, { replace: true });

                  const options = {
                    pathname: `/view-document/${ID}/${e.split(",")[0]}`,
                  };
                  dispatch(getDocumentInfo({ documentID: ID, ID: Number(e.split(",")[0]) }));
                }}
                options={languageOptions}
              />
              {viewDocumentState.data.currentStatus === "QC Ready" ? (
                <Dropdown
                  disabled={loadingRight}
                  menu={{ items: noQcOptions, onClick }}
                  placement="bottomRight">
                  <button className="download-btn">Options</button>
                </Dropdown>
              ) : (
                <Dropdown
                  disabled={loadingRight}
                  menu={{
                    items: normalOptions.map((item) => {
                      if (item.key === "transfertoqc") {
                        if (viewDocumentState.data.isQcRequired) {
                          return { ...item, disabled: true };
                        }
                        return item;
                      } else {
                        return item;
                      }
                    }),
                    onClick,
                  }}
                  placement="bottomRight">
                  <button className="download-btn">Options</button>
                </Dropdown>
              )}
            </div>
          </div>

          <div className="container-wrapper">
            {viewDocumentState.data.fileFormat === "pdf" && (
              <>
                <PDFViewer
                  ref={inputDocumentEditorRef}
                  document={viewDocumentState.data.inputPathPresignedUrl}
                />
                <PDFViewer
                  ref={outputDocumentEditorRef}
                  document={viewDocumentState.data.outputPathPresignedUrl}
                />
              </>
            )}

            {viewDocumentState.data.fileFormat === "docx" && (
              <>
                <DocumentEditorViewer
                  editable={false}
                  document={viewDocumentState.data.sfdtInput}
                  refContainer={inputWordDocumentEditorRef}
                />
                <DocumentEditorViewer
                  editable={false}
                  document={viewDocumentState.data.sfdtOutput}
                  refContainer={outputWordDocumentEditorRef}
                />
              </>
            )}
          </div>
        </>
      )}

      <TransferToQCStatusModal
        open={openTransferToQCConfirmation}
        close={closeTransferToQCConfirmation}
        openState={transferToQcConfirmationOpenState}
      />

      {/* Send to QC */}
      <Modal
        centered
        confirmLoading={viewDocumentState.qcLoading}
        zIndex={1000}
        onOk={() => {
          form.submit();
        }}
        destroyOnClose={true}
        afterClose={() => {
          form.resetFields();
        }}
        title="Transfer to QC"
        width={540}
        open={selectedOutput === "transfertoqc"}
        onCancel={() => {
          setSelectedOutput("");
        }}>
        <div className="mt-3 d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              onFinish={() => {
                transferToQcFunction();
              }}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 19 }}>
              {/* <Form.Item
                label="Select Language(s):"
                name="langs"
                rules={[
                  {
                    required: true,
                    message: "Please select at least 1 document.",
                  },
                ]}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                  <Select
                    disabled={isAllChecked ? true : false}
                    value={selectedLanguages}
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setSelectedLanguages(e)}
                    placeholder="Select document(s)"
                    options={languageOptions}
                  />
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={isAllChecked}
                    onChange={(e) => {
                      setIsAllChecked(e.target.checked);
                    }}
                    defaultChecked={isAllChecked}>
                    <span style={{ marginTop: "-2px" }}>All</span>
                  </Checkbox>
                </div>
              </Form.Item> */}
              <Form.Item
                label="Comment"
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Please input your comment.",
                  },
                ]}>
                <TextArea
                  rows={3}
                  placeholder="Please input your comment."
                  showCount
                  maxLength={100}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

      {/* Email */}
      {/* <Modal
        centered
        zIndex={1000}
        onOk={() => {
          form.submit();
          setSelectedLanguages(["English"]);
        }}
        width={540}
        destroyOnClose={true}
        afterClose={() => {
          form.resetFields();
          setSelectedLanguages(["English"]);
        }}
        title="Send to Email"
        open={selectedOutput === "email"}
        onCancel={() => setSelectedOutput("")}>
        <div className="mt-3 d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              onFinish={() => {
                setSelectedOutput("");
                setSelectedLanguages(["English"]);
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 19 }}>
              <Form.Item
                label="Email address:"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input an email address.",
                  },
                ]}>
                <Input
                  type="email"
                  placeholder="Email Address"
                />
              </Form.Item>
              <Form.Item
                label="Select Language(s):"
                name="langs"
                rules={[
                  {
                    required: true,
                    message: "Please select at least 1 document.",
                  },
                ]}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                  <Select
                    disabled={isAllChecked ? true : false}
                    value={selectedLanguages}
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setSelectedLanguages(e)}
                    placeholder="Select document(s)"
                    options={languageOptions}
                  />
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={isAllChecked}
                    onChange={(e) => {
                      setIsAllChecked(e.target.checked);
                    }}
                    defaultChecked={isAllChecked}>
                    <span style={{ marginTop: "-2px" }}>All</span>
                  </Checkbox>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal> */}

      {/* Download */}
      {/* <Modal
        centered
        zIndex={1000}
        onOk={() => {
          form.submit();
          setIsAllChecked(false);
          setSelectedLanguages(["English"]);
        }}
        footer={[
          <div className="download-modal-footer">
            <button
              disabled={selectedLanguages.length === 0}
              className="download-modal-btn">
              <FontIcon
                style={{ marginTop: "2px", marginRight: "-6px", fontSize: "13px" }}
                iconName="Download"
              />
              <span>Download</span>
            </button>
          </div>,
        ]}
        destroyOnClose={true}
        afterClose={() => {
          form.resetFields();
          setIsAllChecked(false);
          setSelectedLanguages(["English"]);
        }}
        title="Download"
        open={selectedOutput === "download"}
        onCancel={() => {
          setSelectedOutput("");
          setSelectedLanguages(["English"]);
        }}>
        <div
          style={{ zIndex: "100001" }}
          className="mt-3 d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              onFinish={() => {
                setSelectedOutput("");
                setIsAllChecked(false);
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 19 }}>
              <Form.Item
                label="Select Language(s):"
                name="langs"
                rules={[
                  {
                    required: true,
                    message: "Please select at least 1 document.",
                  },
                ]}>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                  <Select
                    disabled={isAllChecked ? true : false}
                    value={selectedLanguages}
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setSelectedLanguages(e)}
                    placeholder="Select document(s)"
                    options={languageOptions}
                  />
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={isAllChecked}
                    onChange={(e) => {
                      setIsAllChecked(e.target.checked);
                    }}
                    defaultChecked={isAllChecked}>
                    <span style={{ marginTop: "-2px" }}>All</span>
                  </Checkbox>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default TranslationDetailPage;
