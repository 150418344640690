import { Spin, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./TableComponent.css";
import { PaginationType } from "../../redux/EngageTranslation/EngageTranslationSlice";
import { fetchPaginateDocuments } from "../../redux/EngageTranslation/EngageTranslationAction";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { TableRowSelection } from "antd/es/table/interface";

interface TableComponentProps {
  dataSource: Array<any>;
  columns: Array<any>;
  noPagination?: boolean;
  loading?: boolean;
  handleTableSorting: (sorter: any) => void;
  tableKey: string | number;
  pagination: PaginationType;
  documentId?: number;
  rowSelection?: TableRowSelection<any>;
}
function TableComponent(props: TableComponentProps) {
  const tableContainerRef = useRef<HTMLDivElement>() as any;

  const dispatch = useAppDispatch();
  const observerRef = useRef(null) as any;
  const fetchPaginateData = () => {
    dispatch(fetchPaginateDocuments({ view: props.pagination.view, documentId: props.documentId }));
  };
  const handleIntersection = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && tableContainerRef.current.scrollHeight > tableContainerRef.current.clientHeight && props.pagination.hasMore) {
      fetchPaginateData();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1, root: tableContainerRef.current });
    if (observerRef.current && props.pagination.hasMore) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [props.pagination.hasMore]);

  // useEffect(() => {
  //   setTableKey((prev) => prev + 1);
  // }, [props.key]);

  return (
    <div
      ref={tableContainerRef}
      className="table-container">
      <Table
        className="table"
        rowKey={(record) => record.id}
        key={props.tableKey}
        bordered
        rowClassName={(record, index) => `${index % 2 === 0 ? "table-row-light" : "table-row-dark"} `}
        size="small"
        loading={props.loading}
        showSorterTooltip={false}
        dataSource={props.dataSource}
        // pagination={props.noPagination ? false : { position: ["bottomCenter"], defaultPageSize: maxRow, pageSizeOptions: pageSizeOption }}
        pagination={false}
        rowSelection={props.rowSelection && props.rowSelection}
        sticky={true}
        columns={props.columns}
        scroll={{ x: "max-content" }}
        footer={() => {
          return (
            <div
              ref={observerRef}
              style={{ height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
              {props.pagination.loading ? (
                <Spin
                  size="large"
                  tip="loading more"
                />
              ) : (
                <></>
              )}
            </div>
          );
        }}
        onChange={(pagination, filters, sorter) => {
          tableContainerRef.current.scrollTo(0, 0);
          props.handleTableSorting(sorter);
        }}
        sortDirections={["descend", "ascend", "descend"]}
      />
    </div>
  );
}

export default TableComponent;
