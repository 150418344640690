import { createSlice } from "@reduxjs/toolkit";
import { auditLogModalClose, auditLogModalData, auditLogModalOpen, clearDocData, clearUploadStatusData, closeDeleteModal, deleteDocument, documentUpload, dropDownLanguages, fetchDocuments, fetchPaginateDocuments, fetchQuickTranslateLanguageList, fetchRefreshDocuments, fetchTargetLanguageList, getDocumentInfo, openDeleteModal, quickTextTranslation, resetTabState, setAutoRefresh, setSelectedName, setSelectedRecord, stopAutoRefresh, switchAutoFresh, transferToQC, updateRequestBody, uploadedStatus } from "./EngageTranslationAction";
import { useNavigate } from "react-router";
import { defaultRequestBody, defaultRequestBodyType, DocumentAuditType, DocumentPropertyType } from "../../constant/document";

interface Language {
  id: number;
  label: string;
  value: number;
}

interface quickTextTranslateDataStateType {
  sourceLanguageList: Language[];
  targetLanguageList: Language[];
  loading: boolean;
  error: string;
}

interface auditLogPageStateType {
  data: DocumentAuditType[];
  resultHeading: string;
  loading: boolean;
  error: string;
  modalStatus: boolean;
  modalData: any;
  pagination: PaginationType;
  currentRequestBody: any;
}

interface DocumentAPIStateType {
  loading: boolean;
  data: DocumentPropertyType[];
  error: string;
}

interface quickTranslationPageType {
  loading: boolean;
  error: any;
  data: { translatedText: string; inputText: string; status: boolean; message: [string] };
}

interface uploadPageType {
  loading: boolean;
  error: any;
  data: { id: number; name: string; description: string; sourceLanguageId: number; targetLanguageId: number; uploadedBy: string; presignedUrl: string };
  uploadStatus: { loading: boolean; data: any; error: string };
}

interface modalsType {
  deleteModalOpenState: boolean;
}

export interface PaginationType {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
  view: string;
}
interface AutoRefreshStateType {
  autoRefresh: boolean;
  currentIntervalId: NodeJS.Timer | undefined;
  error: string | undefined;
  loading: boolean;
}
interface documentTabStateType {
  data: DocumentPropertyType[];
  loading: boolean;
  error: string;
  currentRequestBody: defaultRequestBodyType;
  pagination: PaginationType;
  selectedID: number;
  selectedDocumentID: number;
  selectedDocumentName: string;
  autoRefreshState: AutoRefreshStateType;
}

interface viewDocumentStateType {
  data: any;
  loading: boolean;
  qcLoading: boolean;
  qcSuccess: boolean;
  qcError: string;
  dropDownLanguages: any;
  dropDownLanguagesLoading: boolean;
}

interface uploadedStatusType {
  loading: boolean;
  data: any;
  error: string;
}

interface EngageTranslationMainStateType {
  documentAPIState: DocumentAPIStateType;
  quickTextTranslateDataState: quickTextTranslateDataStateType;
  quickTranslationPage: quickTranslationPageType;
  uploadPage: uploadPageType;
  modals: modalsType;
  documentTabState: documentTabStateType;
  viewDocumentState: viewDocumentStateType;
  auditLogPageState: auditLogPageStateType;
}

interface EngageTranslationStateType {
  engageTranslateMainState: EngageTranslationMainStateType;
}

const engageTranslationInitialState: EngageTranslationStateType = {
  engageTranslateMainState: {
    documentAPIState: {
      loading: false,
      data: [],
      error: "",
    },
    viewDocumentState: {
      data: [],
      loading: false,
      qcLoading: false,
      qcSuccess: false,
      qcError: "",
      dropDownLanguages: [],
      dropDownLanguagesLoading: false,
    },
    quickTextTranslateDataState: {
      sourceLanguageList: [],
      targetLanguageList: [],
      loading: true,
      error: "",
    },
    quickTranslationPage: {
      loading: false,
      error: "",
      data: { translatedText: "", inputText: "", status: false, message: [""] },
    },
    uploadPage: {
      loading: false,
      error: "",
      data: { id: 0, name: "", description: "", sourceLanguageId: 0, targetLanguageId: 0, uploadedBy: "", presignedUrl: "" },
      uploadStatus: {
        loading: false,
        data: [],
        error: "",
      },
    },
    modals: {
      deleteModalOpenState: false,
    },
    documentTabState: {
      data: [],
      loading: false,
      error: "",
      selectedID: 0,
      selectedDocumentID: 0,
      selectedDocumentName: "",
      currentRequestBody: defaultRequestBody,
      autoRefreshState: {
        autoRefresh: true,
        currentIntervalId: undefined,
        error: "",
        loading: false,
      },

      pagination: {
        page: 2,
        pageSize: 30,
        hasMore: true,
        loading: false,
        view: "document",
      },
    },
    auditLogPageState: {
      data: [],
      resultHeading: "",
      loading: true,
      error: "",
      modalStatus: false,
      modalData: {},
      currentRequestBody: {
        DocumentId: 0,
        pageNum: 1,
        pageSize: 15,
        sortColumn: "createdAt",
        isAsc: true,
      },
      pagination: {
        page: 2,
        pageSize: 15,
        hasMore: true,
        loading: false,
        view: "audit-log",
      },
    },
  },
};

const engageTranslationSlice = createSlice({
  initialState: engageTranslationInitialState,
  name: "engageTranslation",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickTranslateLanguageList.pending, (state, action) => {
        state.engageTranslateMainState.quickTextTranslateDataState.loading = true;
      })
      .addCase(fetchQuickTranslateLanguageList.fulfilled, (state, action) => {
        state.engageTranslateMainState.quickTextTranslateDataState.loading = false;
        state.engageTranslateMainState.quickTextTranslateDataState.sourceLanguageList = action.payload.sourceLanguageList;
      })
      .addCase(fetchQuickTranslateLanguageList.rejected, (state, action) => {
        state.engageTranslateMainState.quickTextTranslateDataState.loading = false;
        state.engageTranslateMainState.quickTextTranslateDataState.error = action.error.message ?? "Failed to fetch documents";
      })
      .addCase(quickTextTranslation.pending, (state, action) => {
        state.engageTranslateMainState.quickTranslationPage.loading = true;
      })
      .addCase(quickTextTranslation.fulfilled, (state, action) => {
        state.engageTranslateMainState.quickTranslationPage.data = action.payload.data;
        let messageToUse: any = state.engageTranslateMainState.quickTranslationPage.data.message;
        if (state.engageTranslateMainState.quickTranslationPage.data.message.length > 0) {
          messageToUse = state.engageTranslateMainState.quickTranslationPage.data.message[0].split(`"`)[3];
        }
        state.engageTranslateMainState.quickTranslationPage.error = messageToUse;
        state.engageTranslateMainState.quickTranslationPage.loading = false;
      })
      .addCase(quickTextTranslation.rejected, (state, action) => {
        state.engageTranslateMainState.quickTranslationPage.loading = false;
        state.engageTranslateMainState.quickTranslationPage.error = action.error.message;
      }) //Fetch target language list
      .addCase(fetchTargetLanguageList.pending, (state) => {
        // state.engageTranslateMainState.uploadFormMetaDataState.loading = true;
        state.engageTranslateMainState.quickTextTranslateDataState.loading = true;
      })
      .addCase(fetchTargetLanguageList.fulfilled, (state, action) => {
        state.engageTranslateMainState.quickTextTranslateDataState.loading = false;
        state.engageTranslateMainState.quickTextTranslateDataState.targetLanguageList = action.payload.targetLanguageList;
        // state.engageTranslateMainState.uploadFormMetaDataState.loading = false;
        // state.engageTranslateMainState.uploadFormMetaDataState.targetLanguageList = action.payload.targetLanguageList;
      })
      .addCase(fetchTargetLanguageList.rejected, (state, action) => {
        state.engageTranslateMainState.quickTextTranslateDataState.loading = false;
        state.engageTranslateMainState.quickTextTranslateDataState.error = action.error.message ?? "Failed to fetch documents";
        // state.engageTranslateMainState.uploadFormMetaDataState.loading = false;
        // state.engageTranslateMainState.uploadFormMetaDataState.error = action.error.message ?? "Failed to fetch documents";
      })

      .addCase(documentUpload.pending, (state) => {
        state.engageTranslateMainState.uploadPage.loading = true;
      })
      .addCase(documentUpload.fulfilled, (state, action) => {
        state.engageTranslateMainState.uploadPage.loading = false;
        state.engageTranslateMainState.uploadPage.data = action.payload;
      })
      .addCase(documentUpload.rejected, (state) => {
        state.engageTranslateMainState.uploadPage.loading = false;
      })

      .addCase(fetchDocuments.pending, (state, action) => {
        state.engageTranslateMainState.documentAPIState.loading = true;
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.loading = true;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.loading = true;
            break;
        }
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.loading = false;
            state.engageTranslateMainState.documentTabState.data = action.payload.data;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.data = action.payload.data;
            state.engageTranslateMainState.auditLogPageState.resultHeading = action.payload.searchHeading;
            state.engageTranslateMainState.auditLogPageState.loading = false;
            break;
        }
        state.engageTranslateMainState.documentAPIState.loading = false;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.loading = false;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.loading = false;
            break;
        }
        state.engageTranslateMainState.documentAPIState.loading = false;
        state.engageTranslateMainState.documentAPIState.error = action.error.message ?? "Failed to fetch documents";
      })

      .addCase(fetchRefreshDocuments.pending, (state, action) => {
        state.engageTranslateMainState.documentTabState.autoRefreshState.loading = true;
      })
      .addCase(fetchRefreshDocuments.fulfilled, (state, action) => {
        state.engageTranslateMainState.documentTabState.data = action.payload.results;
        state.engageTranslateMainState.documentTabState.autoRefreshState.loading = false;
      })
      .addCase(fetchRefreshDocuments.rejected, (state, action) => {
        state.engageTranslateMainState.documentTabState.autoRefreshState.error = action.error.message ?? "Failed to fetch documents";
        state.engageTranslateMainState.documentTabState.autoRefreshState.loading = false;
      })
      .addCase(setAutoRefresh, (state, action) => {
        state.engageTranslateMainState.documentTabState.autoRefreshState.currentIntervalId = action.payload.intervalId;
      })
      .addCase(switchAutoFresh, (state, action) => {
        state.engageTranslateMainState.documentTabState.autoRefreshState.autoRefresh = action.payload.autoRefresh;
      })
      .addCase(stopAutoRefresh, (state, action) => {
        clearInterval(state.engageTranslateMainState.documentTabState.autoRefreshState.currentIntervalId);
      })

      .addCase(fetchPaginateDocuments.pending, (state, action) => {
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.pagination.loading = true;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.pagination.loading = true;
            break;
        }
      })
      .addCase(fetchPaginateDocuments.fulfilled, (state, action) => {
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.data = [...state.engageTranslateMainState.documentTabState.data, ...action.payload.data];
            state.engageTranslateMainState.documentTabState.pagination.loading = false;
            state.engageTranslateMainState.documentTabState.pagination.page = state.engageTranslateMainState.documentTabState.pagination.page + 1;
            state.engageTranslateMainState.documentTabState.pagination.hasMore = action.payload.hasMore;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.data = [...state.engageTranslateMainState.auditLogPageState.data, ...action.payload.data];
            state.engageTranslateMainState.auditLogPageState.pagination.loading = false;
            state.engageTranslateMainState.auditLogPageState.pagination.page = state.engageTranslateMainState.auditLogPageState.pagination.page + 1;
            state.engageTranslateMainState.auditLogPageState.pagination.hasMore = action.payload.hasMore;
            break;
        }
      })
      .addCase(fetchPaginateDocuments.rejected, (state, action) => {
        switch (action.meta.arg.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.pagination.loading = false;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.pagination.loading = false;
            break;
        }
        state.engageTranslateMainState.documentAPIState.error = action.error.message ?? "Failed to fetch documents";
      })
      //Upload request body for sort and filter
      .addCase(updateRequestBody, (state, action) => {
        switch (action.payload.view) {
          case "document":
            state.engageTranslateMainState.documentTabState.currentRequestBody = action.payload.requestBody;
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState.currentRequestBody = action.payload.requestBody;
            break;
        }
      })
      .addCase(setSelectedRecord, (state, action) => {
        state.engageTranslateMainState.documentTabState.selectedID = action.payload.selectedID;
        state.engageTranslateMainState.documentTabState.selectedDocumentID = action.payload.selectedDocumentID;
      })
      .addCase(setSelectedName, (state, action) => {
        state.engageTranslateMainState.documentTabState.selectedDocumentName = action.payload.selectedRecordName;
      })
      .addCase(getDocumentInfo.pending, (state) => {
        state.engageTranslateMainState.viewDocumentState.loading = true;
      })
      .addCase(getDocumentInfo.fulfilled, (state, action) => {
        state.engageTranslateMainState.viewDocumentState.loading = false;
        state.engageTranslateMainState.viewDocumentState.data = action.payload;
      })
      .addCase(getDocumentInfo.rejected, (state) => {
        state.engageTranslateMainState.viewDocumentState.loading = false;
      })
      .addCase(transferToQC.pending, (state) => {
        state.engageTranslateMainState.viewDocumentState.qcLoading = true;
      })
      .addCase(transferToQC.fulfilled, (state) => {
        state.engageTranslateMainState.viewDocumentState.qcLoading = false;
        state.engageTranslateMainState.viewDocumentState.qcSuccess = true;
      })
      .addCase(transferToQC.rejected, (state) => {
        state.engageTranslateMainState.viewDocumentState.qcLoading = false;
        state.engageTranslateMainState.viewDocumentState.qcError = "Failed to transfer to QC";
      })
      .addCase(openDeleteModal, (state) => {
        state.engageTranslateMainState.modals.deleteModalOpenState = true;
      })
      .addCase(closeDeleteModal, (state) => {
        state.engageTranslateMainState.modals.deleteModalOpenState = false;
      })
      //For delete document
      .addCase(deleteDocument.pending, (state, action) => {
        state.engageTranslateMainState.documentTabState.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.engageTranslateMainState.documentTabState.loading = false;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.engageTranslateMainState.documentTabState.loading = false;
        state.engageTranslateMainState.documentTabState.error = action.error.message ?? "Failed to delete document";
      })
      .addCase(uploadedStatus.pending, (state, action) => {
        state.engageTranslateMainState.uploadPage.uploadStatus.loading = true;
      })
      .addCase(uploadedStatus.fulfilled, (state, action) => {
        state.engageTranslateMainState.uploadPage.uploadStatus.loading = false;
        state.engageTranslateMainState.uploadPage.uploadStatus.data = action.payload;
      })
      .addCase(uploadedStatus.rejected, (state, action) => {
        state.engageTranslateMainState.uploadPage.uploadStatus.loading = false;
        state.engageTranslateMainState.uploadPage.uploadStatus.error = action.error.message ?? "Failed to fetch document status";
      })
      .addCase(dropDownLanguages.pending, (state, action) => {
        state.engageTranslateMainState.viewDocumentState.dropDownLanguagesLoading = true;
      })
      .addCase(dropDownLanguages.fulfilled, (state, action) => {
        state.engageTranslateMainState.viewDocumentState.dropDownLanguagesLoading = false;
        state.engageTranslateMainState.viewDocumentState.dropDownLanguages = action.payload;
      })
      .addCase(dropDownLanguages.rejected, (state, action) => {
        state.engageTranslateMainState.viewDocumentState.dropDownLanguagesLoading = false;
        state.engageTranslateMainState.uploadPage.uploadStatus.error = action.error.message ?? "Failed to fetch document status";
      })
      .addCase(clearUploadStatusData, (state) => {
        state.engageTranslateMainState.uploadPage = {
          loading: false,
          error: "",
          data: { id: 0, name: "", description: "", sourceLanguageId: 0, targetLanguageId: 0, uploadedBy: "", presignedUrl: "" },
          uploadStatus: {
            loading: false,
            data: [],
            error: "",
          },
        };
      })
      .addCase(clearDocData, (state) => {
        state.engageTranslateMainState.viewDocumentState = {
          data: [],
          loading: false,
          qcLoading: false,
          qcSuccess: false,
          qcError: "",
          dropDownLanguages: [],
          dropDownLanguagesLoading: false,
        };
      })
      .addCase(resetTabState, (state, action) => {
        switch (action.payload) {
          case "document":
            state.engageTranslateMainState.documentTabState = {
              ...engageTranslationInitialState.engageTranslateMainState.documentTabState,
              data: state.engageTranslateMainState.documentTabState.data,
            };
            break;
          case "audit-log":
            state.engageTranslateMainState.auditLogPageState = engageTranslationInitialState.engageTranslateMainState.auditLogPageState;
            break;
        }
      })
      .addCase(auditLogModalOpen, (state) => {
        state.engageTranslateMainState.auditLogPageState.modalStatus = true;
      })
      .addCase(auditLogModalClose, (state) => {
        state.engageTranslateMainState.auditLogPageState.modalStatus = false;
      })
      .addCase(auditLogModalData, (state, action) => {
        state.engageTranslateMainState.auditLogPageState.modalData = action.payload.data;
      });
  },
});

export default engageTranslationSlice.reducer;
